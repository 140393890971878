*{
  margin: 0;padding: 0;box-sizing: border-box;
}

#root { overflow: hidden;}
body { overflow-x: hidden; overflow-y: auto;}
@font-face {
  font-family: 'YourFontName';
  src:  url('../src/assets/bon-vivant-serif.ttf') format('woff2'),
  /* Add more src lines for different font formats if necessary */
}
@font-face {
  font-family: 'YourFontName2';
  src:  url('../src/assets/bon-vivant.ttf') format('woff2'),
  /* Add more src lines for different font formats if necessary */
}
@font-face {
  font-family: 'porter-sans';
  src:  url('../src/font/porter-sans-inline-block.otf') format('utf'),
}
@font-face {
  font-family: 'porter-sans';
  src:  url('../src/font/porter-sans-inline-block.ttf') format('ttf'),
}
@font-face {
  font-family: 'Bon Vivant';
  font-weight: 400;
  src: url('./font/BonVivant-Regular.ttf') format('ttf'),
       url('./font/BonVivant-Regular.ttf') format('ttf'); 
}
@font-face {
  font-family: 'Bon Vivant';
  font-weight: 500;
  src: url('./font/BonVivantSerif.ttf') format('ttf'),
       url('./font/BonVivantSerif.ttf') format('ttf'); 
}
@font-face {
  font-family: 'Bon Vivant';
  font-weight: 600;
  src: url('./font/BonVivantSerifBold.ttf') format('ttf'),
       url('./font/BonVivantSerifBold.ttf') format('ttf'); 
}



@font-face {
  font-family: 'bl-bold';
  src: url('./font/BETA/BLMelody-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'bl-bold';
  src: url('./font/BETA/BLMelody-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
/* Navbar.css */
nav{
  position: absolute;
  display: flex;
  z-index: 100;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}
.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 50px;
  height: 100px;
  gap: 100px;
  background: none;
  position: relative;
}

img {
  max-width: 100%;
  height: auto;
}

@media(max-width: 768px) {
  .photo-slide- { width: 90%; margin: auto;}
  .photo-slide { width: 90%; margin: auto; height: 400px; object-fit: cover; border-radius: 10px;}
}


ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 40px;
}

li {
  list-style: none;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.hamburger {
  display: none; /* Initially hide on larger screens */
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 6px 0;
}
#nava{
  display: flex;
  flex-direction: row;
}
/* Responsive styles */
@media screen and (max-width: 768px) {
  nav{
    position: static;
  }
  .navbar {
    flex-direction: column;
    padding: 20px;
  }

  nav ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #333;
  }

nav ul.open {
    display: flex;
    margin-top: 30px;
    z-index: 10;
    padding: 50px;
    background-color: white;
  }

  nav li {
    margin: 10px 0;
  }

  .hamburger {
    display: inline; /* Show on smaller screens */
  }
}

.hero-section { display: flex; flex-direction: row; }
.hero-section-left , .hero-section-right { width: 50%; }

.contact-section { display: flex; flex-direction: row; height: auto; position: relative;}
.ab-section { display: flex; flex-direction: row; position: relative;}
.contact-section-left, .contact-section-right { width: 50%; }
.ab-section-left, .ab-section-right { width: 50%; }
.contact-section-right { width: 50%; padding-top: 180px;}

@media (max-width: 768px) {
  .contact-section { height: auto;}
  .contact-section-right { padding-top: 70px; padding-left: 20px; padding-right: 20px;}
}

/* .hero-section-right { width: 346.31px; height: 346.31px;} */
.blue{
  height: 866px;
  width: 866px;
  background-color: rgba(2, 74, 185, 1);
  border-radius: 50%;
  margin-top: -200px;
  margin-left: 100px;
  position: relative;
}
.contact-section .blue{
  height: 346.31px;
  width: 346.31px; display: none;
  background-color: rgba(2, 74, 185, 1);
  border-radius: 50%;
  top: 0; right: 0;
  margin-top: -100px;
  right: -100px;
  margin-left: 0;
  position: absolute;
}
@media(max-width: 768px) {
  .contact-section .blue { top: 110px; z-index: -1; display: none;}
}
.ab-section .blue{
  height: 346.31px;
  width: 346.31px;
  background-color: rgba(2, 74, 185, 1);
  border-radius: 50%;
  top: 0; right: 0;
  margin-top: -100px;
  right: -100px;
  margin-left: 0;
  position: absolute;
}
@media(max-width: 768px) {
  .ab-section .blue { top: 110px; z-index: -1; display: none;}
}
.blue::before{
  position: absolute; content: ""; 
  /* background-image: url('./assets/hero-wrap.png');  */
  height: 866px; width: 880px; left: -30px;
}

.orange{
  background-color: rgba(255, 122, 5, 1);
  width: 530px;
  height: 530px;
  border-radius: 50%;
  margin-left: 150px;
  margin-top: 100px;
  
}
.contact-section .orange{
  background-color: rgba(255, 122, 5, 1);
  width: 215.14px;
  height: 215.14px;
  border-radius: 50%;
  margin-left: 90px;
  margin-top: 84px;
  
}
.ab-section .orange{
  background-color: rgba(255, 122, 5, 1);
  width: 215.14px;
  height: 215.14px;
  border-radius: 50%;
  margin-left: 90px;
  margin-top: 84px;
  
}
.yellow{
  height: 540px;
  width: 540px;
  border-radius: 50%;
  background-color: rgba(255, 212, 51, 1);
  margin-top: -250px;
  margin-left: 100px;
}
.contact-section .yellow{
  height: 215.94px;
  width: 215.94px;
  border-radius: 50%;
  background-color: rgba(255, 212, 51, 1);
  margin-top: -92px;
  margin-left: 93px;
}
.ab-section .yellow{
  height: 215.94px;
  width: 215.94px;
  border-radius: 50%;
  background-color: rgba(255, 212, 51, 1);
  margin-top: -92px;
  margin-left: 93px;
}

@media(max-width: 768px) {
  .blue { height: 500px; width: 520px;}
  .orange { height: 350px; width: 350px;}
  .yellow { height: 350px; width: 350px;}
}
.hero-section-left{
  padding-top: 150px;
  padding-left: 50px; position: relative; z-index: 1;
}
.contact-section-left{
  padding-top: 150px;
  padding-left: 50px; position: relative; z-index: 1;
}
.award{
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 10px;
}
.award p{
  font-family: 'Josefin Sans', sans-serif !important;
  font-size: 16px !important;
  font-weight: 300 !important;

}
.hero-section-left h1{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-family: 'bl-bold', sans-serif;
  font-size: 44px; line-height: 66px;
  font-weight: 700;
 

}
.line2{
  margin-top: -80px;
}
.hero-section-left p{
  font-family: 'YourFontName', sans-serif;
  font-size: 18px;
  font-weight: 700;
 
}

.home-logo { object-fit: cover; height: 50px; }
@media(max-width: 768px) {
  .home-logo { height: 30px;}
}
.hero-section-left button{
Height:
56px;
padding: 0 26px;
border-radius: 30px;
background-color: rgba(255, 122, 5, 1);
font-family: 'bl-bold', sans-serif;
font-size: 18px;
font-weight: 400;
color: rgba(255, 255, 255, 1);
border: none;
}
.box , .box2 , .box3{
  Width:
  374px;
  Height:
  119.09px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 15px;
  box-shadow: 4px 4px 60px 0 rgba(0, 0, 0, 0.1);
transform: rotate(-10deg);
display: flex;
flex-direction: row;
padding: 20px;
gap: 20px;
align-items: center;
justify-content: space-between;

}
.box{

margin-top: -500px;

margin-left: 200px;


}
.box2{
  margin-top: 30px;
  margin-left: 100px;
}
.box3{
  margin-top: 18px;
margin-left: 200px;


}
.pie{
  Width:
62.79px;
Height:
53.76px;
background-color: rgba(255, 234, 228, 1);
border-radius: 15px;
display: flex;
justify-content: center;
align-items: center;
}
.tick{
  height: 26px;
  width: 26px;
  background-color: rgba(246, 246, 251, 1);
  border-radius: 50%;
  display: flex;
justify-content: center;
align-items: center;
}
.box-des h4{
  font-size: 18px;
font-family: 'bl-bold', sans-serif;
  font-weight: 400;
  color: rgba(2, 74, 184, 1);

}
.box-des h5{
  font-size: 32px;
  font-weight: 700;
  font-family: 'YourFontName', sans-serif;
}
.wave{
  margin-top: -150px;
}
.ocean { 
  height: 5%;
  width:100%;
  position: absolute;
  bottom:-200px;
  left:0;
  background: #ffd433;
}

.wave {
  background: url('./assets/wave-img.svg') repeat-x; 
  /* background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;  */
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,5px,0);
  }
}

@media(max-width: 1114px){
  .ocean { bottom: -400px;}
}
@media(max-width: 1104px){
  .wave-overflow { display: none;}
}
.experience{ position: relative; }
.wave-overflow {  position: absolute; top: 0;}
.hero-section::-webkit-scrollbar {
  width: 0px; /* Adjust as needed */
}
.contact-section::-webkit-scrollbar {
  width: 0px; /* Adjust as needed */
}
.experience{
  background-color: rgba(255, 212, 51, 1);
margin-top: -10px;
padding: 50px;
padding-bottom: 100px;
}
.experience h1{
  font-family: 'YourFontName2';
font-weight: 400;
font-size: 56px;
}
.experience h2{
  font-family: 'bl-bold', sans-serif;
  font-weight: 700;
font-size: 40px;
margin-top: -30px;
}
.exp-cards{
display: flex;
gap: 14px;
flex-direction: row;
justify-content: space-between;
}
.exp-card{
 width: 300px;

}
.exp-card-img{
  height: 93px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  background-repeat: no-repeat;
  padding: 10px;
  padding-left: 0;
  /* background-position: center; */
  /* background-image: url(../src//assets/Vector\ \(1\).svg); */
}
.exp-card h3{
  font-family: 'bl-bold', sans-serif;
  font-size: 18px;
  font-weight: 700;
}
.exp-card p{
  font-size: 14px;
  font-weight: 400; line-height: 25px;
  font-family: 'bl-bold', sans-serif !important;

}
.habitat{
  height: 129px;
transform: rotate(-5deg);
display: flex;
position: absolute; top:-50px; left: -20px;
justify-content: center;
align-items: center;
background-color: #024AB9;
}
.habitat-section{
  height: 270px;   overflow: hidden; width: 100%;
position: relative;
}
.habitat-section{
  height: 270px;   overflow: hidden; width: 100%;
position: relative;
}
.reverse-wave { transform: rotate(180deg); margin-top:  -4px;}
.habitat h1{
  font-size: 80px;
  text-transform: capitalize;
  font-weight: 400;
  font-family: 'bl-bold';
color: white; 

}
/* @keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
} */

.habitat-section {
  padding-top: 50px;
  background-color: rgba(255, 212, 51, 1);
}

.habitat h1 {
  font-size: 92px;
  font-weight: 400;
  font-family: 'bl-bold';
  color: white;
  white-space: nowrap;
  animation: marquee 10s linear infinite;
}

@media(max-width: 768px) {
  .experience { padding-bottom: 30px;}
  .habitat { height: 70px; }
  .habitat h1 { font-size: 42px; }
  .habitat-section { height: 150px; }
}
.Amenities{
  padding: 50px;
  background-color: rgba(0, 0, 0, 1);
}

.Amenities h1{
  font-family: 'YourFontName2', sans-serif;
  font-size: 40px;
  font-weight: 400;
  color: white;

}
.Amenities p{
  font-size: 18px;
  font-weight: 700;
  text-align: left; line-height: 28px;
  text-transform: capitalize;
  color: white;
  font-family: 'bl-bold', sans-serif !important;
}
.photos-section { max-width: 1350px; margin: auto; padding-bottom: 125px; padding-top: 50px;}
.photos-title { font-family: 'bl-bold'; font-weight: 700; font-size: 64px; line-height: 79.68px; color: #000000; text-align: center; margin-bottom: 62px;}
.photos-section .slick-active { padding-top: 51px; transition: all 0.5s;}
.photos-section .slick-center { padding-top: 102px; transition: all 0.5s; }

@media(max-width: 768px) {
  .Amenities { padding: 50px 20px;}
  .photos-section { padding-bottom: 60px; }
  .photos-section .slick-active { padding-top: 0px; transition: all 0.5s;}
  .photos-section .slick-center { padding-top: 0px; transition: all 0.5s; }
  .photos-title { font-size: 38px; line-height: 50.68px; margin-bottom: 22px;}
}
.Amenities-card{
  width: 200px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Josefin Sans', sans-serif !important;
  font-size: 20px;
  font-weight: 400;
  margin-top: 100px;

}
.Amenities-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Amenities-card {
  width: calc(33.33% - 20px); /* 20px is the total margin/padding you want between the cards */
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .Amenities-cards { justify-content: space-between;}
  .Amenities-card {
    width: calc(50% - 20px); margin-top: 20px;
  }
  .Amenities-card p { text-align: center;}
}

.residents{
  padding: 0 87px;
}
.photos-box { padding: 94px 0; padding-left: 94px; background-color: #2A2A29;}
.residents h1{
  font-size: 40px;
  font-weight: 400;
  font-family: 'YourFontName2', sans-serif;

}

@media(max-width: 768px) {
  .photos-box { padding: 40px; padding-left: 20px;}
}
.residents-card{
  Width:
630px;
Height:
566px;
/* background-color: black; */
background-image: url(../src/assets/Rectangle\ 15.svg);
padding: 50px;
}
.residents-card h1{
  font-size: 32px;
  font-weight: 700;
  font-family: 'YourFontName', sans-serif;
  color: white;

}
.residents-card p{
  color: white;

  font-size: 18px;
  font-weight: 400;
  font-family: 'Josefin Sans', sans-serif !important;

}
.residents-cards {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent items from wrapping to the next line */
}
.residents-cards::-webkit-scrollbar {
  display: none;
}
.wave2{
  width: 100%;
  margin-top: -90px;
}

.Explore{
  overflow: hidden;
  padding: 50px 82px;
  background-color: black;
  position: relative;
}
.Explore h1{
  font-family: 'YourFontName2', sans-serif;
  font-size: 40px;
  font-weight: 400;
  color: white;
}

@media(max-width: 768px) {
  .Explore { padding: 20px;}
}
.Explore-boxs{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: white;
  gap: 30px;
  font-size: 32px;
  font-weight: 400;
  font-family: 'Josefin Sans', sans-serif !important;
}
.Explore-box1{
  Width:
45%;
Height:
453px;
  background-image: url(../src/assets/g/g4.png);
  background-repeat: no-repeat;
  padding: 20px;
  padding-top: 400px;
  border-radius: 20px;

}
.Explore-box2{
  background-repeat: no-repeat;
  padding: 20px;
  padding-top: 400px;
  border-radius: 20px;

  Width:
45%;
Height:
453px;
  background-image: url(../src/assets/g/g5.png);
}
.exp{
  background-color: black !important;
}
.exp2{
  background-color: #FFD433 !important;
}
.exp2{
  background-color: #FFD433 !important;
}
.property{
background-color: #FBFBFB;
}
.property-card{
  Width:
300px;
Height:
357px;
border-radius: 16px;
background-color: #ffffff;
padding: 10px;
}
.property-card .pro-img { width: 100%;}
.abc{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  color: rgba(0, 0, 0, 0.6);

  font-size: 12px;
  font-weight: 400;
  padding-top: 10px;
}
.property-card h2{
  font-family: 'YourFontName', sans-serif;
  font-size:18px;
  font-weight: 700;
  color: #333333;

}
.property-card h2 span{
  color: #0053BF;
}
.property-card h3{
  font-family: 'YourFontName', sans-serif;
  font-size:22px;
  font-weight: 600;
}
.property-card p{
  font-family: 'Josefin Sans', sans-serif ;

  font-size:14px;
  font-weight: 400;
}
.property-cards{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 50px;
}
@media(max-width:1190px){
  .box{

    margin-top: -500px;
    
    margin-left: 100px;
    
    
    }
    .box2{
      margin-top: 50px;
      margin-left: 30px;
    }
    .box3{
      margin-top: 50px;
    margin-left: 100px;
    
    
    }
    .exp-card{
      width: 200px;
     
     }

}
@media(max-width:970px){
  .hero-section{
    flex-direction: column-reverse;

  }
  .contact-section{
    flex-direction: column-reverse;

  }

  .contact-section-left , .contact-section-right{
    width: 100%;
  }
  .blue{
    margin-top: 50px;
    margin-left: 50%;
  
  }
  .yellow{
    margin-top: -100px;
    margin-left: 100px;
  }
  .box{

    margin-top: -500px;
    
    margin-left: 400px;
    
    
    }
    .box2{
      margin-top: 50px;
      margin-left: 300px;
    }
    .box3{
      margin-top: 50px;
    margin-left: 400px;
    
    
    }
    .exp-card{
      width: 150px;
     
     }
}
@media(max-width:860px){

  .blue{
    margin-top: 50px;
    margin-left: 30%;
  
  }

  .box{

    margin-top: -500px;
    
    margin-left: 30%;
    
    
    }
    .box2{
      margin-top: 50px;
      margin-left: 20%;
    }
    .box3{
      margin-top: 50px;
    margin-left: 30%;
    
    
    }
    .hero-section-right{
padding-bottom: 100px;
    }
    .contact-section-right{
padding-bottom: 50px;
    }
}
@media(max-width:570px){
  .blue{
    margin-top: 50px;
    margin-left: 10%;
  
  }

  .box{

    margin-top: -500px;
    
    margin-left: 25%;
    
    
    }
    .box2{
      margin-top: 50px;
      margin-left: 20%;
    }
    .box3{
      margin-top: 50px;
    margin-left: 25%;
    
    
    }
}
@media(max-width:570px){
  .box , .box2 , .box3{
    Width:
    300px;
    Height:
    119.09px;
    transform: rotate(8deg);
  }
  .box{

    margin-top: -500px;
    
    margin-left: 25%;
    
    
    }
    .box2{
      margin-top: 0px;
      margin-left: 20%;
    }
    .box3{
      margin-top: 26px;
    margin-left: 25%;
    
    
    }
    .hero-section-left h1{
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 10px;
      font-family: 'bl-bold', sans-serif;
      font-size: 24px;
      font-weight: 700; line-height: 56px;}

}

@media(max-width:768px){
  .exp-cards{

    flex-direction: column;
  }
  .exp-card{
    width: auto;
    margin-bottom: 50px;
   
   }
   .Explore-boxs{
    flex-direction: column;
   }
   .Explore-box1 , .Explore-box2{
    width: 100%;
    margin-top: 20px;
    background-size: cover;
    background-position: center;
   }
}
@media(max-width:550px){
  .residents-cards{
    display: flex;
    flex-direction: column;
  overflow: hidden;

    
  }

  .residents-card{
   width: 100% !important;
  Height:
  566px;
  padding: 50px;
  white-space: normal;
 
}
.hero-section-left{
  padding-top: 30px; width: 100%;
  padding-left: 20px; padding-bottom: 40px;
}
.contact-section-left{
  padding-top: 30px;
  padding-left: 20px; padding-bottom: 40px; padding-right: 20px;
}

}
.property-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.property-card {
  width: calc(25% - 20px); /* 4 cards in a row on laptop size */
  margin-bottom: 20px;
}

@media (max-width: 1024px) {
  .property-card {
    width: calc(50% - 20px); /* 2 cards in a row on tablet size */
  }
}

@media (max-width: 600px) {
  .property-card {
    width: calc(100% - 20px); /* 1 card in a row on mobile size */
  }
}

.footer{
  background-color: black;
align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  padding-top: 20px;
  /* margin-top: -20px; */
  padding-bottom: 50px;

}

/* .footer1{
  width: 30%;
} */
 
.footer2 ul {gap: 20px; }
.footer2:first-child { max-width: 150px; width: 100%;}
.footer2 { max-width: 400px; width: 100%;}

.footer-box { display: flex; gap: 30px; align-items: flex-start; justify-content: flex-start; width: auto;}
@media(max-width: 768px) {
  .footer-box { display: flex; gap: 30px; align-items: flex-start; justify-content: flex-start; width: auto; flex-direction: column;}
}
.footer1 h1{
font-size: 32px;
font-weight: 700;
font-family: 'YourFontName';

}
.footer1 p{
  font-size: 18px;
  font-weight: 400;
  font-family: 'bl-bold', sans-serif;

  }
.copy-text{ background-color: #000000; color: #FFFFFF; padding: 20px 0; text-align: center;
  font-size: 18px;
  font-weight: 400;
  font-family: 'bl-bold', sans-serif;

  }
  .footer ul{
    font-size: 20px;
font-weight: 700;
font-family: 'YourFontName';
  }
  .footer ul li{
    font-size: 18px;
font-weight: 400;
font-family: 'bl-bold', sans-serif;
  }
  footer { background-color: #000000;}
  .footer-col{
    padding: 50px;
    background-color: black;
    margin-top: -10px;
    padding-top: 100px;
  }
  .footer-bottom{
    border-top: 1px solid rgba(255, 255, 255, 0.3);
padding-top: 30px;
  }
  .footer-list { display: flex; flex-direction: column;}
  @media (max-width: 600px){
    .footer-col{
      display: flex;
      flex-direction: column-reverse;
    }
    .footer-list { display: flex; flex-direction: column; align-items: flex-start;}
    .footer-bottom{
      border: none;
    }
    .footer{
      flex-direction: column;
    }
    .footer1{
      width: 80%;
      display: flex; align-items: center; flex-direction: column;
      margin-bottom: 50px;
    }
    .footer1 p { text-align: center;}
    /* .footer ul li{
      display: none;
      
    } */
    .footer ul{
      margin-top: 20px;
    }
  }


  .modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0; z-index: 100;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .modal.open {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background: #fff;
    /* padding: 20px 40px; */ padding-bottom: 40px;
    max-width: 1048px;
    height: 700px; overflow-y: scroll;
    position: relative;
    width: 100%;
    border-radius: 16px;
  }

.modal-title-box { background-color: #024AB9; text-align: center; padding: 16px 0;}
  .modal-content::-webkit-scrollbar { display: none;}
  
  .close-button {
    /* position: absolute; */
    /* right: 0; top: -44px; */ margin-top: 16px; margin-right: 40px; margin-bottom: 16px;
    background-color: transparent;
    padding: 4px;
    cursor: pointer;
    border: none;
    border-radius: 50px;
  }

  .list-item { text-decoration: none; color: #000; cursor: pointer;}
.close-button img {height: 30px; width: 30px; border-radius: 50%; object-fit: cover;}
.modal-title { font-family: 'Josefin Sans', sans-serif; font-size: 24px; line-height: 24px; font-weight: 600; color: #FFFFFF;}

.modal-form-container { display: grid; flex-direction: column; column-gap: 64px; row-gap: 24px; grid-template-columns: 1fr 1fr; padding: 36px 82px;}
.modal-input-box { display: flex; flex-direction: column; gap: 8px;}
.modal-input-title { font-family: 'Josefin Sans', sans-serif; font-size: 18px; line-height: 18px; font-weight: 400; color: #000;}
.modal-input { background-color: #FBFBFB; border: none; padding: 14px; border-radius: 8px; outline: 1px solid #E2E2E2; font-family: 'Josefin Sans', sans-serif; font-size: 16px; font-weight: 400; width: 100%; position: relative;}
/* .modal-input::after { position: absolute; content: ""; background-image: url('./assets/date.svg'); height: 24px; width: 24px; right: 10px; top: 50%; transform: translateY(-50%); background-color: #FFF; } */
.modal-select { background-color: #FBFBFB; border: none; padding: 14px; border-radius: 8px; outline: 1px solid #E2E2E2; font-family: 'Josefin Sans', sans-serif; font-size: 16px; font-weight: 400;}
.modal-radio { font-family: 'Josefin Sans', sans-serif; font-size: 14px; font-weight: 400; color: #000; display: flex; gap: 8px;}
.modal-checkbox { font-family: 'Josefin Sans', sans-serif; font-size: 14px; font-weight: 400; color: #000; display: flex; gap: 8px; align-items: center;}
.modal-btn { display: flex; gap: 20px; width: 100%; margin: 20px 0; margin-top: 82px; justify-content: center;}
.modal-btn .submit-btn { background-color: transparent; border: none; cursor: pointer;}
.submit-btn-bottom { padding: 0px 82px;}
.modal-check-box { display: flex; align-items: center; gap: 14px; margin-top: 14px;}

@media(max-width: 768px) { 
  .modal-content { max-width: 350px;}
  .modal-form-container { grid-template-columns: 1fr; padding: 22px 22px;}
  .close-button { margin: 16px;}
  .modal-title { font-size: 12px; line-height: 16px;}
  .modal-title-box { font-size: 24px; line-height: 24px;}
  .submit-btn-bottom { padding: 0 22px;}
  .modal-check-box { flex-direction: column; align-items: start; gap: 14px; margin-top: 14px;}
  .modal-btn { margin-top: 40px;}
}

.wave-item { margin-top: -5px;}


#rssBlock{
  left: -20px; top: 70px;
  background: #024AB9;
  position: absolute;
  width: 106%; z-index: 11;
  overflow: hidden; transform: rotate(-5deg);
}
#rssBlock2{
  left: -20px; bottom: 0px;
  background: #024AB9;
  position: absolute;
  width: 106%; z-index: 11;
  overflow: hidden; transform: rotate(-5deg);
}

/*remove p*/
.cnnContents {
  width:100%;
  padding-top: 20px;
  margin:0 auto;
  font-size: 30px;
  white-space: nowrap;
  text-transform: uppercase;
  font-family: 'bl-bold';
  font-weight: 400;
}

.marqueeStyle {
  display:inline-block;
  font-size: 80px;
  text-transform: capitalize;
  font-weight: 400;
  font-family: 'bl-bold';
  color: white; 
  /* Apply animation to this element */
  -webkit-animation: scrolling-left1 20s linear infinite;
  animation: scrolling-left1 20s linear infinite;
}

.marqueeStyle2 {
  display:inline-block;
  font-size: 80px;
  text-transform: capitalize;
  font-weight: 400;
  font-family: 'bl-bold';
  color: white; 
  /* Apply animation to this element */
  -webkit-animation: scrolling-left2 20s linear infinite;
  animation: scrolling-left2 20s linear infinite;
  animation-delay: 10s;
}
/* scrolling-left is continuous/repeatly text */
@keyframes scrolling-left1 {
    0% {transform: translateX(100%);
        -webkit-transform: translateX(100%);}
        100% {transform: translateX(-100%);
          -webkit-transform: translateX(-100%);}
}
@keyframes scrolling-left2 {
  0% {transform: translateX(0%);
    -webkit-transform: translateX(0%);}
    100% {transform: translateX(-200%);
      -webkit-transform: translateX(-200%);}
    }
    
    @-webkit-keyframes scrolling-left1 {
      0% {-webkit-transform: translateX(100%);}
      100% {-webkit-transform: translateX(-100%);}
    }
    @-webkit-keyframes scrolling-left2 {
    0% {-webkit-transform: translateX(0%);}
    100% {-webkit-transform: translateX(-200%);}
  }
  

  @media(max-width: 768px) {
  #rssBlock { top: 20px; left: -10px;}
  .marqueeStyle { font-size: 42px; }
  .marqueeStyle2 { font-size: 42px; }
}

.contact-title { font-family: 'Bon Vivant'; font-weight: 700; font-size: 48px; line-height: 28px; color: #333333; text-transform: uppercase; margin-top: 24px;}
.contact-text { font-family: 'Josefin Sans', sans-serif; font-weight: 400; font-size: 22px; line-height: 35px; color: #333333; max-width: 612.74px; margin-top: 16px;}


.contact-box { background-color: #1A191A; position: relative;}
.contact-wrap { background-image: url('./assets/contact-bg.svg'); height: 213.76px; width: 932.51px; background-repeat: no-repeat; position: absolute; left: 0;}

.contact-form { padding: 80px 130px; display: grid; grid-template-columns: 1fr 1fr;}
.have-title { font-family: 'Bon Vivant'; font-weight: 700; font-size: 48.24px; line-height: 60.06px; color: #FFFFFF; margin-bottom: 16px;}
.have-text { font-family: 'Josefin Sans', sans-serif !important; font-weight: 400; font-size: 22.64px; line-height: 37.64px; color: #FFFFFF; margin-bottom: 16px;}

.contact-input { font-family: 'Josefin Sans', sans-serif !important; font-weight: 400; font-size: 15.06px; line-height: 37.64px; max-width: 228.58px; width: 100%; color: #F2F2F2; background-color: transparent; border: none; border-bottom: 1px solid #F2F2F2; outline: none;}
.contact-input::placeholder { font-family: 'Josefin Sans', sans-serif !important; font-weight: 400; font-size: 15.06px; line-height: 37.64px; color: #F2F2F2;}
.form-inputs { display: grid; grid-template-columns: 1fr 1fr; gap: 15px;}


@media(max-width: 768px) {
  .contact-title { font-size: 30px;}
  .contact-text { font-size: 20px; line-height: 28px;}
  .contact-form { padding: 40px 20px;  grid-template-columns: 1fr;}
}
.about-section { padding-top: 140px; padding-left: 80px; padding-right: 80px; padding-bottom: 70px;}
.about-box { display: flex; gap: 30px; margin-top: 50px;}
.about-box .about-text { width: 100%; font-family: 'Josefin Sans', sans-serif; font-size: 18px; line-height: 26px; color: #000000;}

@media(max-width: 768px) {
  .about-section { padding-top: 40px; padding-left: 20px; padding-right: 20px;}
  .about-box { margin-top: 20px; flex-direction: column;}
}

.add-section { background-image: url('./assets/about-bg.svg'); height: 858px; width: 100%; background-position: center; background-size: cover; background-repeat: no-repeat; padding-top: 200px; padding-left: 130px; padding-right: 130px; display: flex; position: relative;}


.add-left { display: flex; flex-direction: column; gap: 20px; width: 100%;}
.add-right { width: 100%;}
.add-box { background-color: #FFFFFF; border-radius: 5.89px; padding: 18px 16px; padding-right: 40px; position: relative; max-width: 349.43px; width: 100%; overflow: hidden;}
.add-box:nth-child(2) { margin-left: 52px;}
.add-box:nth-child(2)::after { background-image: url('./assets/wrap-3.svg'); width: 104px; height: 64px; position: absolute; content: ""; background-repeat: no-repeat; right: -20px; top: 0;}
.add-box::after { background-image: url('./assets/wrap-2.svg'); width: 104px; height: 64px; position: absolute; content: ""; background-repeat: no-repeat; right: -20px; top: 0;}
.add-title { font-family: 'Bon Vivant'; font-weight: 600; font-size: 21.05px; color: #024AB8;}
.add-text { font-family: 'Josefin Sans', sans-serif; font-weight: 400; font-size: 16.84; color: #000000; margin-top: 10px; opacity: .60;}


@media(max-width: 768px) {
  .add-section { height: auto; padding-top: 100px; padding-left: 20px; padding-right: 20px; flex-direction: column-reverse; gap: 40px; padding-bottom: 130px;}
  .add-box:nth-child(2) { margin-left: 0;}
  #rssBlock2 { bottom: 14px; left: -10px;}
}

.fd-title { font-family: 'YourFontName2'; font-weight: 400; font-size: 56px; color: #FFFFFF;}
.fdd-title { font-family: 'YourFontName2'; font-weight: 400; font-size: 56px; color: #000000;}
.fd-sub-title { font-family: 'Josefin Sans', sans-serif; font-weight: 700; font-size: 40px; color: #FFFFFF; margin-bottom: 16px; font-style: italic;}
.fd-text { font-family: 'Bon Vivant' !important; font-weight: 400; font-size: 18px; color: #000;}

.project-box { margin-top: 27px; display: flex; align-items: center; gap: 80px;}
.count-box { position: relative; }
.count-box .count-num { position: absolute; font-family: 'Bon Vivant'; font-size: 32px; font-weight: 600; transform: translate(-50%, -50%); top: 50%; left: 50%; }
.count-title { font-family: 'Josefin Sans', sans-serif; font-size: 16px; font-weight: 400; line-height: 25px; text-align: center; color: #000000; margin-top: 10px; }

.who-section { padding-top: 160px; padding-left: 80px; padding-right: 80px; padding-bottom: 112px;}

.who-box { display: flex; align-items: center; gap: 65px;}
.who-title { font-family: 'Josefin Sans', sans-serif; font-weight: 700; font-style: italic; font-size: 40px; line-height: 40px; color: #000000; margin-top: 54px; margin-bottom: 22px;}
.who-text { font-family: 'Bon Vivant'; font-weight: 700; font-size: 18px; line-height: 26px; color: #000000e6; max-width: 578px; width: 100%;}

@media(max-width: 768px) {
  .who-section { padding-top: 60px; padding-left: 20px; padding-right: 20px; padding-bottom: 50px;}
  .who-box { flex-direction: column;}
  .who-title { margin-top: 22px; margin-bottom: 22px; font-size: 20px; line-height: 30px;}
  .who-text { font-size: 14px; line-height: 22px;}
}
.contact-btn { border: none; background-color: transparent; margin-top: 38px;}

.story-section { background-color: #000000; position: relative; padding: 75px 0 75px 88px;}
.story-section::after { background-image: url('./assets/photos/photo8.png'); position: absolute; content: ""; height: 606px; width: 562px; right: 0; top: 80px;}

@media(max-width: 768px) { 
  .story-section { padding: 50px 20px;}
  .story-section::after { display: none;}
}
.out-text-box { max-width: 733px; margin-top: 53px; width: 100%; display: flex; flex-direction: column; gap: 45px;}
.out-text-box .our-text { font-family: 'Josefin Sans', sans-serif; font-weight: 400; font-size: 18px; line-height: 26px; color: #FFF;}

.faq-section { padding: 99px 85px; background-color: #024AB9;}
.faq-box { padding-top: 95px; display: flex; gap: 80px;}
.faq-box .faq-left { width: 100%;}
.faq-box .faq-right { width: 100%; display: flex; flex-direction: column; gap: 16px;}
.faq-drop { display: flex; align-items: center; gap: 19px; width: 100%; justify-content: space-between; padding-bottom: 20px; overflow: hidden;}
.faq-drop .faq-text { font-family: 'Bon Vivant'; font-size: 18px; font-weight: 700; line-height: 26px; color: #FFFFFF;}
.faq-drop .faq-plush { font-family: 'Bon Vivant'; font-size: 40px; font-weight: 700; line-height: 35.9px; color: #FFFFFF;}
.faq-sub-text { font-family: 'Josefin Sans', sans-serif; font-size: 16px; font-weight: 400; line-height: 26px; color: #FFFFFF; transition: ease-in 400ms; overflow: hidden;}

@media(max-width: 768px) { 
  .faq-section { padding: 50px 20px;}
  .faq-box { padding-top: 50px; flex-direction: column; gap: 30px;}
 }
.faq-item { border-bottom: 1px solid #B8B8B8; padding-bottom: 28px;}

input[type="date"]::-webkit-calendar-picker-indicator {
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  background-position: right;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; background-size: 20px;
  width: auto;
}


.modal-card { background-color: #024AB9; font-family: 'Josefin Sans', sans-serif; padding: 16px 0; font-weight: 700; font-size: 24px; line-height: 24px; display: flex; justify-content: center; color: #FFFFFF; align-items: center;}


.modal-card-container { display: grid; grid-template-columns: 1fr 1fr; gap: 25px; padding-top: 36px; padding-left: 76px; padding-right: 76px; padding-bottom: 42px;}



@media(max-width: 768px) {
  .modal-card-container { grid-template-columns: 1fr; padding: 20px;}
}


.terms-section { align-items: center; background-color: #FFFFFF; display: flex; height: 200px; justify-content: center; padding-left: 30px; padding-right: 30px; }
.terms-title { color: #000000; font-family: 'Josefin Sans', sans-serif; text-align: center; font-size: 40px; padding-top: 120px;}
.term-content { padding: 40px 80px 0 80px;}
.terms-text { font-size: 20px; font-weight: 400; font-family: 'Josefin Sans', sans-serif; margin-bottom: 14px;}
.ul-li-text { font-size: 20px; font-weight: 400; font-family: 'Josefin Sans', sans-serif;}
.terms-c-title { font-size: 32px; font-weight: 600; font-family: 'Josefin Sans', sans-serif; padding-top: 30px; margin-bottom: 16px;}


.foot-title {  font-size: 18px; font-weight: 400; font-family: 'bl-bold', sans-serif;}
.foot-text {  font-size: 18px; font-weight: 400; font-family: 'bl-bold', sans-serif;}